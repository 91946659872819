:root {
    --color: rgba(60, 60, 60, .8);
    --green1: rgb(3, 85, 38);
    --green: rgba(60, 200, 40, .9);
    --white: rgba(255, 255, 255, 1);
    --blue: rgba(0, 0, 50, .9);
    --gray: #282828e6;
    /* --golden: #DAA520; */
    --golden: #daae3f;
    /* --golden: rgba(255, 80, 80, .8); */
    /* --golden: rgba(255, 80, 80, .8); */
    --heading: calc(15px + .5vw);
    --heading1: calc(20px + 1vw);
    --heading2: calc(25px + 1.5vw);
    --heading3: calc(30px + 2vw);
    /* chakras colours  */
    --crown-chakra: #d55dee;
    --third-eye-chakra: #4482ee;
    --throat-chakra: #3ad3d2;
    --heart-chakra: #8fd33b;
    --manipur-chakra: #ffba39;
    --savadishthana-chakra: #ff862e;
    --muladhar-chakra: #fe4e53;
    /* chakras colours  */

    /* margins */
    --mt: calc(10px + .5vw);
    --ml: calc(10px + .5vw);
    --mr: calc(10px + .5vw);
    --mb: calc(10px + .5vw);

    --mt1: calc(10px + .5vw);
    --ml1: calc(10px + .5vw);
    --mr1: calc(10px + .5vw);
    --mb1: calc(10px + .5vw);


    /* padding */
    --p1: calc(10px + .5vw);
    --p2: calc(15px + .7vw);
    --p3: calc(20px + .9vw);
    --p4: calc(25px + 1.1vw);
}