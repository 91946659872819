* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.flexWrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.postCardWrapper {
    display: flex;
    flex-direction: column;
}

.postCard {
    flex: calc(370px + 10vw) 1 auto;
    max-width: 500px;
    width: calc(370px + 10vw);
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 5px 10px 25px -10px rgba(200, 200, 200, 1);
    border-radius: 4px;
}

.postCard:nth-child(1) {
    margin-top: 50px;
}

.postCard .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0px 6px 15px;
    border-bottom: 1px solid rgba(240, 240, 240, 1);
}

.postCard .header .imgwrapper {
    width: 60px;
    height: 60px;
    display: block;
    justify-content: center;
    align-items: center;
}

.postCard .header .imgwrapper img {
    max-width: 60px;
    max-height: 60px;
    border-radius: 100%;
}


.postCard .tag {
    margin-left: 8px;
    background: var(--golden);
    padding: 1px 15px;
    border-radius: 4px;
    color: white;
    font-weight: 600;
}

.postCard .date {
    margin-right: 8px;
}

.postCard .header .username {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 14px;
}

.postCard .header .username span {
    font-size: 15px;
    line-height: 15px;
}

.postCard .header .username span:nth-child(1) {
    font-weight: 600;
}

.postCard .header .username span:nth-child(2) {
    font-size: 14px;
    float: left;
}

.postCard .header .editBox {
    position: relative;
    padding-right: 8px;
    padding-left: 8px;
    z-index: 10;
}


.postCard .header .dropdown {
    position: absolute;
    top: 100%;
    left: 0px;
    display: none;
    flex-direction: column;
    background: white;
    padding: 3px 20px;
    box-shadow: 0px 10px 20px -8px rgba(170, 170, 170, 1);
    border-radius: 4px;
    padding-top: 8px;
    border: 1px solid var(--golden);
}

.postCard .header .dropdown span {
    cursor: pointer;
    margin-bottom: 5px;
    transition: .2s;
}

.postCard .header .dropdown span:hover {
    color: var(--golden);
}

.postCard .header .editBox:hover .dropdown {
    display: flex;
}

.postCard .postCardBody .postCardImgWrapper {
    max-height: 400px;
    max-width: 100%;
    display: flex;
    justify-content: center;
}


.postCard .postCardBody .postCardImgWrapper img,
.postCard .postCardBody .postCardImgWrapper video {
    max-height: 400px;
    max-width: 100%;
}



.postCard .postCardFooter {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    align-items: center;
    padding-bottom: 10px;
}

.postCard .postCardFooter .iconCard svg {
    font-size: calc(20px + .8vw);
    margin-right: 7px;
    stroke-width: .1px;
    color: red;
}

.postCard .postCardFooter .counter {
    font-size: calc(15px + .4vw);
    margin-top: 2px;
}

.pointer {
    cursor: pointer;
}

.blogsSortWrapper {
    flex: calc(150px + 10vw) 1 1;
    min-width: calc(150px + 10vw);
    max-width: calc(150px + 10vw);
    position: sticky !important;
    -webkit-sticky: sticky;
    top: 110px;
}