/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */
/* margin start margin start margin start margin start  */
/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */

/* margin-top */
.mt1 {
    margin-top: var(--mt1) !important;
}

.mt2 {
    margin-top: calc(var(--mt1) * 1.5) !important;
}

.mt3 {
    margin-top: calc(var(--mt1) * 2) !important;
}

.mt4 {
    margin-top: calc(var(--mt1) * 2.5) !important;
}

.mt5 {
    margin-top: calc(var(--mt1) * 3) !important;
}

/* margin left */
.ml1 {
    margin-left: var(--ml1) !important;
}

.ml2 {
    margin-left: calc(var(--ml1) * 1.5) !important;
}

.ml3 {
    margin-left: calc(var(--ml1) * 2) !important;
}

.ml4 {
    margin-left: calc(var(--ml1) * 2.5) !important;
}

.ml5 {
    margin-left: calc(var(--ml1) * 3) !important;
}

/* margin right */
.mr1 {
    margin-right: var(--mr1) !important;
}

.mr2 {
    margin-right: calc(var(--mr1) * 1.5) !important;
}

.mr3 {
    margin-right: calc(var(--mr1) * 2) !important;
}

.mr4 {
    margin-right: calc(var(--mr1) * 2.5) !important;
}

.mr5 {
    margin-right: calc(var(--mr1) * 3) !important;
}

/* margin bottom */
.mb1 {
    margin-bottom: var(--mb1) !important;
}

.mb2 {
    margin-bottom: calc(var(--mb1) * 1.5) !important;
}

.mb3 {
    margin-bottom: calc(var(--mb1) * 2) !important;
}

.mb4 {
    margin-bottom: calc(var(--mb1) * 2.5) !important;
}

.mb5 {
    margin-bottom: calc(var(--mb1) * 3) !important;
}

/* margin inline */
.mx1 {
    margin-left: var(--mb1) !important;
    margin-right: var(--mb1) !important;
}

.mx2 {
    margin-left: calc(var(--mb1) * 1.5) !important;
    margin-right: calc(var(--mb1) * 1.5) !important;
}

.mx3 {
    margin-left: calc(var(--mb1) * 2) !important;
    margin-right: calc(var(--mb1) * 2) !important;
}

.mx4 {
    margin-left: calc(var(--mb1) * 2.5) !important;
    margin-right: calc(var(--mb1) * 2.5) !important;
}

.mx5 {
    margin-left: calc(var(--mb1) * 3) !important;
    margin-right: calc(var(--mb1) * 3) !important;
}

/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */
/* margin end margin end margin end margin end */
/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */



/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */
/* Padding start Padding start Padding start Padding start  */
/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */

/* padding-top */
.pt1 {
    padding-top: var(--pt1) !important;
}

.pt2 {
    padding-top: calc(var(--pt1) * 1.5) !important;
}

.pt3 {
    padding-top: calc(var(--pt1) * 2) !important;
}

.pt4 {
    padding-top: calc(var(--pt1) * 2.5) !important;
}

.pt5 {
    padding-top: calc(var(--pt1) * 3) !important;
}


/* padding left */
.pl1 {
    padding-left: var(--pl1) !important;
}

.pl2 {
    padding-left: calc(var(--pl1) * 1.5) !important;
}

.pl3 {
    padding-left: calc(var(--pl1) * 2) !important;
}

.pl4 {
    padding-left: calc(var(--pl1) * 2.5) !important;
}

.pl5 {
    padding-left: calc(var(--pl1) * 3) !important;
}

/* margin right */
.pr1 {
    padding-right: var(--pr1) !important;
}

.pr2 {
    padding-right: calc(var(--pr1) * 1.5) !important;
}

.pr3 {
    padding-right: calc(var(--pr1) * 2) !important;
}

.pr4 {
    padding-right: calc(var(--pr1) * 2.5) !important;
}

.pr5 {
    padding-right: calc(var(--pr1) * 3) !important;
}

/* margin bottom */
.pb1 {
    padding-bottom: var(--pb1) !important;
}

.pb2 {
    padding-bottom: calc(var(--pb1) * 1.5) !important;
}

.pb3 {
    padding-bottom: calc(var(--pb1) * 2) !important;
}

.pb4 {
    padding-bottom: calc(var(--pb1) * 2.5) !important;
}

.pb5 {
    padding-bottom: calc(var(--pb1) * 3) !important;
}

/* margin inline */
.px1 {
    padding-left: var(--pl1) !important;
    padding-right: var(--pr1) !important;
}

.px2 {
    padding-left: calc(var(--pl1) * 1.5) !important;
    padding-right: calc(var(--pr1) * 1.5) !important;
}

.px3 {
    padding-left: calc(var(--pl1) * 2) !important;
    padding-right: calc(var(--pr1) * 2) !important;
}

.px4 {
    padding-left: calc(var(--pl1) * 2.5) !important;
    padding-right: calc(var(--pr1) * 2.5) !important;
}

.px5 {
    padding-left: calc(var(--pl1) * 3) !important;
    padding-right: calc(var(--pr1) * 3) !important;
}

/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */
/* padding end padding end padding end padding end */
/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */