@import url("./root.css");
@import url("./initialCss.css");


@font-face {
    font-family: 'baloo';
    src: url(../googleFonts/Baloo_2/static/Baloo2-Regular.ttf);
}

@font-face {
    font-family: 'tenali';
    src: url(../googleFonts/Tenali_Ramakrishna/TenaliRamakrishna-Regular.ttf);
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    font-family: "baloo";
    font-size: 18px;
    color: rgba(40, 40, 40, .9);
    /* margin-bottom: 100px; */
    scroll-behavior: smooth;
}


.CustomBar {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: rgba(0, 0, 50, .9);
    color: white;
    width: 100%;
    padding: 5px calc(10px + .5vw);
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-size: var(--heading);
    font-family: baloo;
}

.heading1 {
    font-size: var(--heading1);
    font-family: baloo;

}

.heading2 {
    font-size: var(--heading2);
    font-family: baloo;
    line-height: calc(var(--heading2) / 2);
}

.heading3 {
    font-size: var(--heading3);
    font-family: baloo;
}

/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */
/* margin start margin start margin start margin start  */
/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */

/* margin-top */
.mt1 {
    margin-top: var(--mt1) !important;
}

.mt2 {
    margin-top: calc(var(--mt1) * 1.5) !important;
}

.mt3 {
    margin-top: calc(var(--mt1) * 2) !important;
}

.mt4 {
    margin-top: calc(var(--mt1) * 2.5) !important;
}

.mt5 {
    margin-top: calc(var(--mt1) * 4) !important;
}

.mtJumbo {
    margin-top: calc(var(--mt1) * 5) !important;
}

/* margin left */
.ml1 {
    margin-left: var(--ml1) !important;
}

.ml2 {
    margin-left: calc(var(--ml1) * 1.5) !important;
}

.ml3 {
    margin-left: calc(var(--ml1) * 2) !important;
}

.ml4 {
    margin-left: calc(var(--ml1) * 2.5) !important;
}

.ml5 {
    margin-left: calc(var(--ml1) * 3) !important;
}

/* margin right */
.mr1 {
    margin-right: var(--mr1) !important;
}

.mr2 {
    margin-right: calc(var(--mr1) * 1.5) !important;
}

.mr3 {
    margin-right: calc(var(--mr1) * 2) !important;
}

.mr4 {
    margin-right: calc(var(--mr1) * 2.5) !important;
}

.mr5 {
    margin-right: calc(var(--mr1) * 3) !important;
}

/* margin bottom */
.mb1 {
    margin-bottom: var(--mb1) !important;
}

.mb2 {
    margin-bottom: calc(var(--mb1) * 1.5) !important;
}

.mb3 {
    margin-bottom: calc(var(--mb1) * 2) !important;
}

.mb4 {
    margin-bottom: calc(var(--mb1) * 2.5) !important;
}

.mb5 {
    margin-bottom: calc(var(--mb1) * 3) !important;
}

/* margin inline */
.mx1 {
    margin-left: var(--mb1) !important;
    margin-right: var(--mb1) !important;
}

.mx2 {
    margin-left: calc(var(--mb1) * 1.5) !important;
    margin-right: calc(var(--mb1) * 1.5) !important;
}

.mx3 {
    margin-left: calc(var(--mb1) * 2) !important;
    margin-right: calc(var(--mb1) * 2) !important;
}

.mx4 {
    margin-left: calc(var(--mb1) * 2.5) !important;
    margin-right: calc(var(--mb1) * 2.5) !important;
}

.mx5 {
    margin-left: calc(var(--mb1) * 3) !important;
    margin-right: calc(var(--mb1) * 3) !important;
}


.my1 {
    margin-top: var(--mt1) !important;
    margin-bottom: var(--mb1) !important;
}

.my2 {
    margin-top: calc(var(--mt1) * 1.5) !important;
    margin-bottom: calc(var(--mb1) * 1.5) !important;
}

.my3 {
    margin-top: calc(var(--mt1) * 2) !important;
    margin-bottom: calc(var(--mb1) * 2) !important;
}

.my4 {
    margin-top: calc(var(--mt1) * 2.5) !important;
    margin-bottom: calc(var(--mb1) * 2.5) !important;
}

.my5 {
    margin-top: calc(var(--mt1) * 3) !important;
    margin-bottom: calc(var(--mb1) * 3) !important;
}

/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */
/* margin end margin end margin end margin end */
/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */



/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */
/* Padding start Padding start Padding start Padding start  */
/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */

/* padding-top */
.pt1 {
    padding-top: var(--pt1) !important;
}

.pt2 {
    padding-top: calc(var(--pt1) * 1.5) !important;
}

.pt3 {
    padding-top: calc(var(--pt1) * 2) !important;
}

.pt4 {
    padding-top: calc(var(--pt1) * 2.5) !important;
}

.pt5 {
    padding-top: calc(var(--pt1) * 3) !important;
}


/* padding left */
.pl1 {
    padding-left: var(--pl1) !important;
}

.pl2 {
    padding-left: calc(var(--pl1) * 1.5) !important;
}

.pl3 {
    padding-left: calc(var(--pl1) * 2) !important;
}

.pl4 {
    padding-left: calc(var(--pl1) * 2.5) !important;
}

.pl5 {
    padding-left: calc(var(--pl1) * 3) !important;
}

/* margin right */
.pr1 {
    padding-right: var(--pr1) !important;
}

.pr2 {
    padding-right: calc(var(--pr1) * 1.5) !important;
}

.pr3 {
    padding-right: calc(var(--pr1) * 2) !important;
}

.pr4 {
    padding-right: calc(var(--pr1) * 2.5) !important;
}

.pr5 {
    padding-right: calc(var(--pr1) * 3) !important;
}

/* margin bottom */
.pb1 {
    padding-bottom: var(--pb1) !important;
}

.pb2 {
    padding-bottom: calc(var(--pb1) * 1.5) !important;
}

.pb3 {
    padding-bottom: calc(var(--pb1) * 2) !important;
}

.pb4 {
    padding-bottom: calc(var(--pb1) * 2.5) !important;
}

.pb5 {
    padding-bottom: calc(var(--pb1) * 3) !important;
}

/* margin inline */
.px1 {
    padding-left: var(--pl1) !important;
    padding-right: var(--pr1) !important;
}

.px2 {
    padding-left: calc(var(--pl1) * 1.5) !important;
    padding-right: calc(var(--pr1) * 1.5) !important;
}

.px3 {
    padding-left: calc(var(--pl1) * 2) !important;
    padding-right: calc(var(--pr1) * 2) !important;
}

.px4 {
    padding-left: calc(var(--pl1) * 2.5) !important;
    padding-right: calc(var(--pr1) * 2.5) !important;
}

.px5 {
    padding-left: calc(var(--pl1) * 3) !important;
    padding-right: calc(var(--pr1) * 3) !important;
}

/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */
/* padding end padding end padding end padding end */
/* +++++++++ +++++++++ +++++++++ +++++++++ +++++++++  */

.a {
    color: white;
    text-decoration: none;
}

.a:hover {
    color: white;
}

.indexTopCard {
    flex: 1;
    width: 100%;
    --min-height: 400px;
    min-height: var(--min-height);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    place-items: center;
    padding: calc(160px - 5vw) calc(5px + .7vw) 50px calc(5px + .7vw);
    box-shadow: 0 10px 10px -10px #daae3f56;
}

.indexTopCard .animatedTextContainer {
    flex: 500px 1 1;
    display: flex;
    flex-direction: column;
    place-items: center;
    margin-top: calc(52px - 4vw);
}

.indexTopCard .animatedTextContainer .animatedText {
    font-size: calc(20px + 2vw);
    font-weight: 600;
    color: var(--golden);
    text-shadow: 0 1px 1px rgba(0, 0, 50, .9);
    min-height: calc(20px + 2.4vw);
    line-height: calc(20px + 2vw);
}

.indexTopCard .imgContainer {
    flex: 500px 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-items: center;
    position: relative;
    margin-top: 50px;
    /* margin-top: 40px; */
}

.indexTopCard .imgContainer .chakraImg {
    position: absolute;
    z-index: 0;
    margin: auto;
    width: calc(170px + 11vw) !important;
    max-width: calc(170px + 11vw) !important;
    max-height: calc(170px + 11vw) !important;
    height: calc(170px + 11vw) !important;
    animation: anime 30s linear infinite;
}

.Chakras_Name {
    z-index: 10;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 1);
    color: #222;
    backdrop-filter: blur(5px);
    padding: 4px 10px;
    font-size: calc(20px + .5vw);
    text-align: center;
    transition: .4s !important;
    box-shadow: 0 0 10px -5px rgba(0, 0, 0, .5);
    border-radius: 4px;
    line-height: calc(20px + .5vw);
    height: 40px;
    padding-bottom: 0px;
    padding-top: 12px;
    margin-top: 10px;
}

@keyframes anime {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



.indexTopCard .imgContainer img {
    height: calc(145px + 8vw) !important;
    width: calc(145px + 8vw) !important;
    z-index: 1;
}

.buttonContainer {
    margin-top: 20px;
    width: 100%;
    display: grid;
    place-items: center;
}

.buttonContainer a {
    text-decoration: none;
    color: var(--golden);
    font-size: calc(20px + .5vw);
    padding: 7px 30px;
    border: 2px solid var(--golden);
    border-radius: 7px;
    background: transparent;
    transition: .3s;
}

.buttonContainer a:hover {
    background: white;
}

.buttonWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.buttonWrapper .button {
    width: calc(100px + 1vw);
    padding: calc(5px + .3vw);
    display: flex;
    justify-content: center;
    background: var(--white);
    margin: calc(10px + .6vw) 10px calc(0px + .6vw) calc(3px + .6vw);
    text-decoration: none;
    color: var(--golden);
    border-radius: 6px;
    font-size: 18px;
    border: 1px solid var(--golden);
    box-shadow: 0 3px 0px var(--golden);
    transition: .5s;
}

.buttonWrapper .button:hover {
    background: var(--golden);
    box-shadow: 0 0px 0px;
    color: white;
}

.upcoming {
    /* background: var(--white); */
    color: white;
    margin-top: 20px;
    font-size: calc(16px + .4vw);
    padding: 3px 20px;
    mix-blend-mode: color-dodge;
    letter-spacing: .3px;
}


/* menubar */
.menuBar {
    width: 100%;
    height: calc(60px + 1vw);
    background: var(--white);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: var(--p3);
    padding-right: var(--p3);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
}

.menuBar a {
    color: black;
    text-decoration: none;
}

.menuBar .contactContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    color: black !important;
}

.menuBar .menuContainer {
    position: absolute;
    top: 40px;
    left: auto;
    right: auto;
    background-color: var(--golden);
    width: calc(100% - (var(--p3) * 2));
    height: calc(55px + 1vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: var(--p3);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, .2);
}

.menuBar .menuContainer .logo {
    min-height: 100%;
    background: white;
    padding-left: var(--p3);
    padding-right: calc(var(--p3) * 1.5);
    clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
}

.menuBar .menuContainer .logo img {
    max-height: calc(55px + 1vw);
}

.menuBar .menuContainer .toggleBtn {
    display: grid;
    flex-direction: column;
    width: 40px;
    height: 25px;
    background: transparent;
    place-items: center;
    cursor: pointer;
}

.menuBar .menuContainer .toggleBtn span {
    background: white;
    width: 100%;
    height: 3px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 5px;
    transition: 2s;
}

.menuBar .menuContainer .toggleBtn span:hover {
    background: green;
    width: 100%;
    height: 3px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 5px;
}

.menuBar .menuItemsContainer {
    display: flex;
}

.menuBar .menuItemsContainer .menuItem {
    color: var(--white);
    text-decoration: none;
    margin-left: calc(var(--ml) * 2);
    cursor: pointer;
    font-size: calc(15px + .5vw);
}

.menuBar .menuItemsContainer .menuItem a {
    cursor: pointer;
}

/* menubar */


/* outline card */
.OutLineCard {
    width: 100%;
    padding: 12px 15px 0px 15px;
    background: transparent !important;
    border: 3px solid var(--golden);
    color: var(--golden);
    display: flex;
    place-items: center;
    justify-content: center;
    border-radius: calc(5px + .5vw);
    font-size: calc(15px + .5vw);
    text-align: center;
    text-decoration: none;
}

.OutLineCard .title {
    font-size: 20px;
}

.OutLineCard:hover {
    color: var(--golden);
    background-color: transparent !important;
    box-shadow: 0 10px 10px #b072069d;
}

.golden {
    color: var(--golden);
}

/* outline card */


/* full page */
.fullPageText {
    /* background: rgb(27, 129, 47); */
    /* background: rgb(60, 16, 0); */
    /* background: rgb(149, 2, 75); */
    /* background: rgba(122, 149, 2, 0.98); */

    height: 100%;
    padding: calc(25px + .7vw) calc(0px + 1.4vw);
    width: 100%;
    height: auto;
    color: white;
    font-weight: 600;
    font-size: calc(18px + .2vw);
    text-align: center;
    position: relative;
    letter-spacing: .5px;
}

.fullPageText h1 {
    font-size: var(--heading2);
}

.fullPageText .chakraText {
    position: absolute;
    font-size: calc(13px);
    color: rgba(255, 255, 255, .5);
    right: 10px;
    top: 2px;
}

.lines {
    max-height: 50px !important;
    /* max-width: 100px; */
}

/* full page */



.imgCard {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    place-items: center;
    width: 100%;
    height: auto;
    margin-top: 90px;
}

.imgCard .imgContainer {
    flex: 400px 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--p1);
    max-height: 500px;
}

.imgCard .imgContainer img {
    max-height: 500px;
    width: auto;
}

.imgCard .textContainer {
    flex: 400px 1 1;
    /* max-width: 100%; */
    color: var(--green1);
    font-size: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--p1);
}


/* user card start*/
.teacherCardContainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.userCard {
    flex: 350px 1 1;
    width: 100%;
    max-width: 350px;
    height: 490px !important;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    align-self: center;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 20px;
    box-sizing: border-box;
    position: relative;
}

.userCard:hover img {
    transform: scale(1.1);
}

.userCard .imgContainer {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    height: 250px;
    min-width: 100%;
    padding: 0px;
    overflow: hidden;
}

.userCard .imgContainer img {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    height: 250px !important;
    width: 100%;
    margin-left: auto;
    transition: 2s;
}

.userCard .userTitle {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.userCard .userTitle .title {
    font-size: 20px;
    font-weight: 600;
}

.userCard .userTitle .name {
    font-size: 20px;
    margin-left: 10px;
}

.userCard .about {
    padding: 10px;
}

.readMoreBtn {
    position: absolute;
    bottom: 5px;
    right: 10px;
}

/* user card end*/


/* why pankaj */
.whyPankaj {
    display: flex;
    flex-direction: column;
    background: var(--golden);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, .2);
    text-align: center;
}

.whyPankaj .heading3 {
    text-align: center;
}

.whyPankaj .textContainer {
    background: #cc93034e;
    padding: 20px 20px;
    color: white;
}

.whyPankaj .textContainer .heading {
    font-size: calc(30px + 2vw);
    text-shadow: 0 3px 3px rgba(0, 0, 0, .5);
}

.whyPankaj .text {
    font-size: calc(20px + .4vw);
}

/* bulletin card */
.wrapper {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.wrapper .bulletinCard {
    flex: 330px 1 1;
    max-width: 330px;
    width: 100%;
    height: 500px;
    padding: 0px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .2);
    margin: 20px;
    display: grid;
    place-items: center;
    flex-direction: column;
    position: relative;
    background: white;
    color: black;

}

.wrapper .bulletinCard .imgContainer {
    height: 200px;
    width: 330px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}


.wrapper .bulletinCard .icon {
    font-size: calc(60px + 3vw);
}

.wrapper .bulletinCard img {
    max-height: 200px;
    max-width: 330px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.wrapper .bulletinHeading {
    font-size: calc(20px + .5vw);
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.wrapper .bulletinText {
    padding: 10px;
    overflow-y: auto;
    height: 250px;
    display: grid;
    place-items: center;
    box-sizing: border-box;
}

.wrapper .answerTab {
    position: absolute;
    bottom: 10px;
    right: 5px;
    height: 15px;
    padding: 2px;
    color: blue;
    z-index: 6;
}

.wrapper .answerCard {
    position: absolute;
    top: 0px;
    left: 0px;
    background: var(--golden);
    min-width: 100%;
    min-height: 100%;
    z-index: 5;
    border-radius: 5px;
    display: grid;
    place-items: center;
    font-size: calc(20px + .5vw);
    color: white;
}



/* bulletin card */



.pointer {
    cursor: pointer;
}

/* why pankaj */





/* Button */

.button {
    text-decoration: none;
    display: flex;
    position: relative;
}

.button .tooltipCard {
    display: flex;
    bottom: 100%;
    left: 0px;
    width: 100%;
    position: absolute;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    z-index: 1;
    border-radius: 5px;
    left: -15px;
    background: white;
    padding: 5px;
    display: none;
    font-weight: 600;
    color: var(--golden);
    border: 1px solid var(--golden);
}


.index0 {
    z-index: -9;
}

.button:hover .tooltipCard {
    display: block;
}


.button .icon,
.animatedArrow {
    min-width: 50px;
    max-width: 50px;
    animation: arrow .7s linear infinite;

}

@keyframes arrow {
    0% {
        padding-left: 0px;
    }

    50% {
        padding-left: 10px;
    }

    100% {
        padding-left: 0px;
    }

}

/* Button */

/* footer */
.footer {
    flex: 1;
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 100px;
    width: 100%;
    padding: calc(20px + 2vw) calc(13px + 2vw);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: rgba(0, 0, 0, .1);
    box-shadow: 0 -20px 20px -20px rgba(0, 0, 0, .3);
    color: black !important;
}


.footer .first,
.footer .second,
.footer .third {
    flex: 1 1 1;
    max-width: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
}

.footer .third a,
.footer .second a {
    text-decoration: none;
    color: black;
    margin-top: 6px;
    transition: .3s;
}

.footer .third a:hover,
.footer .second a:hover {
    color: var(--golden);
    padding-left: calc(10px);
}

.footer .first img {
    max-height: 90px;
}



/* footer */

/* social icons */

.SocialIcons {
    display: flex;
    align-items: center;
}

.SocialIcons a {
    text-decoration: none;
    color: black;
}

.SocialIcons a:hover {
    color: var(--golden);
}

.SocialIcons span {
    margin-right: 5px;
}

.SocialIcons svg {
    margin-right: 13px;
}

/* social icons */


/* disabled  */

.disabled {
    cursor: not-allowed;
    pointer-events: none;
}


/* disabled  */



/* Video Container */
.videoContainer {
    max-height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    place-items: center;
    margin-top: 110px;
    position: relative;
    z-index: 0;
}

.videoContainer video {
    width: 100%;
    max-width: 100%;
    max-height: 400px;
    z-index: 1;
}

.videoContainer .icon {
    z-index: 2;
    font-size: 50px;
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;
    box-sizing: content-box;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: .5s;
}

.videoContainer:hover.videoContainer .icon {
    opacity: 1;
    pointer-events: all;
}

/* Video Container */

/* banner */

.bannerWrapper {
    position: relative;
    width: 100%;
    display: flex;
    place-items: center;
    justify-content: center;
    margin-top: calc(71px + 1vw);
}

.bannerWrapper div {
    position: absolute;
    font-size: calc(15px + 5vw);
    padding: calc(10px + 1.7vw) calc(20px + .5vw) calc(4px + 1.7vw) calc(20px + .5vw);
    font-weight: 900;
    color: white;
    text-transform: capitalize;
    transition: .7s;
    line-height: calc(20px + 2.5vw);
}

.bgGreen {
    background: var(--green1);
}

.rightImgContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 100%;
    direction: rtl;
    padding: 4px;
    height: auto;
    place-items: center;
    padding-left: calc(25px + .5vw);
    padding-right: calc(25px + .5vw);
}

.rightImgContainer .imgContainer {
    flex: 450px 1 1;
    width: 100%;
    max-height: 500px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.rightImgContainer .imgContainer img {
    max-height: 500px;
    max-width: 450px;
}

.rightImgContainer .textContainer {
    flex: 500px 1 1;
    display: flex;
    flex-direction: column;
    max-width: auto;
    width: 100%;
    direction: ltr;
    margin-top: 10px;
}

/* 
.rightImgContainer .textContainer .scheduleTable {
    overflow-y: auto;
    max-width: auto;
    max-width: calc(100%;
} */
/* 
.rightImgContainer .textContainer .scheduleTable table td {
    padding-top: auto;
} */

.rightImgContainer .textContainer b {
    font-size: calc(20px + .5vw);
    text-transform: capitalize;
    color: var(--golden);
}

.rightImgContainer .textContainer tr td:nth-child(1) {
    min-width: 120px;
}

/* Testimonial card */
.testimonialsContainer {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    place-items: center;
    padding-left: 30px;
    padding-right: 30px;

}

.testimonialsContainer .testimonials {
    flex: 330px 1 1;
    width: 100%;
    height: 520px;
    max-width: 330px;
    display: flex;
    margin: 15px;
    flex-direction: column;
    box-sizing: border-box;
    place-items: center;
}

.testimonialsContainer .testimonials .testimonialsImg {
    height: 200px;
    width: 100%;
    display: grid;
    place-items: center;
}

.testimonialsContainer .testimonials .testimonialsImg img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    box-shadow: 0 -10px 10px -3px rgba(40, 40, 40, .2);
}

.testimonialsContainer .testimonials .testimonialsHeading {
    color: var(--golden);
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    /* text-shadow: 0 -2px 2px rgba(40, 40, 40, .9); */
}

.testimonialsContainer .testimonials .testimonialsText {

    display: grid;
    place-items: center;
    height: 300px;
    box-shadow: 0 -10px 10px rgba(40, 40, 40, .2);
    border-radius: 8px;
    padding: 10px;
    overflow-y: auto;
}

/* Testimonial card */


/* hover card */


/* hover card */


/* =================================================== */
/* SteppersSteppers SteppersSteppers SteppersSteppers */
/* ================================================= */

.steppers {
    display: inline-flex !important;
    flex-direction: row !important;
    position: absolute;
    top: calc(95px + 1vw);
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2;
    left: calc(20px + 1vw);
    color: white;
    background: rgba(0, 0, 0, .3);
}

.steppers .stepperArrow {
    margin-left: 10px;
    margin-right: 10px;
}

.steppers a {
    text-decoration: none;
    color: white;
    font-weight: bold;
}

/* =================================================== */
/* SteppersSteppers SteppersSteppers SteppersSteppers */
/* ================================================= */

/* =================================================== */
/* Contact Us Contact Us Contact Us Contact Us  */
/* ================================================= */

.contactus {
    width: 100%;
    height: calc(100px + 20vw);
    background: var(--golden);
    margin-top: calc(95px + 1vw);
    display: grid;
    place-items: center;
    position: relative;

}

.contactus .textContainer {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    text-align: center;

}

.contactus .textContainer .text {
    font-size: calc(20px + 4vw);
    font-weight: bolder;
    color: white;
    text-shadow: 0 8px 8px rgba(0, 0, 0, .2);
    z-index: 1;
    line-height: calc(28px + 4vw);
}

.contactus .textContainer .icon {
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    padding: calc(2px + .1vw);
    width: calc(17px + 1.2vw);
    height: calc(17px + 1.2vw);
    border: 1px solid white;
    border-radius: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.contactus .textContainer .icon:hover {
    color: var(--green1);
    border: 1px solid var(--green1);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
}



.contactus .first,
.contactus .second {
    position: absolute;
    width: calc(150px + 3vw);
    height: calc(70px + 3vw);
    z-index: 0;
}

.zIndex1 {
    z-index: 1;
}

.contactus .first {
    left: 0px;
    top: 0px;
    background: rgba(80, 200, 80, .5);
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
}

.contactus .second {
    right: 0px;
    bottom: 0px;
    background: rgba(200, 80, 80, .5);
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
}

.contactUsContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 850px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .2);
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
}

.contactUsContainer .form,
.contactUsContainer .address {
    flex: 380px 1 1;
    max-width: 380px;
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
}



/* =================================================== */
/* Contact Us Contact Us Contact Us Contact Us  */
/* ================================================= */


/* =================================================== */
/* Custom Table Custom Table Custom Table Custom Table  */
/* ================================================= */

.responsiveTable {
    min-width: 100% !important;
    padding: 0px;
    display: inline-flex;
    flex-direction: row;
    height: auto;
    box-sizing: border-box;
}

.responsiveTable thead {
    box-sizing: border-box;
}

.responsiveTable thead tr {
    display: flex;
    flex-direction: column;
    box-shadow: 10px 0 10px rgba(0, 0, 0, .1);
}

.responsiveTable thead tr th {
    display: flex;
    width: calc(80px + 2vw);
    height: auto;
    height: 45px;
    background: var(--golden);
    color: white;
    text-align: center;
}

.responsiveTable tbody {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    box-sizing: border-box;
}

.responsiveTable tbody tr {
    display: flex;
    flex-direction: column;
}

.responsiveTable tbody tr td {
    display: inline-flex;
    width: calc(300px);
    height: 45px;
    border-right: 1px solid rgba(0, 0, 0, .1);
    text-align: center !important;
}

.responsiveTable tbody tr td:first-child {
    border-top: 1px solid rgba(0, 0, 0, .1);
}

/* =================================================== */
/* Custom Table Custom Table Custom Table Custom Table  */
/* ================================================= */


.striped {
    flex: 1;
    width: 100%;
    padding: 10px 20px;
    background: var(--golden);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    place-items: center;
    margin-left: auto;
    margin-right: auto;
}

.striped div {
    padding-top: 10px;
    flex: calc(94px + 4vw) 1 1;
    width: 100%;
    margin: 15px;
    background: var(--golden);
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    place-items: center;
    max-height: calc(94px + 4vw);
    max-width: calc(94px + 4vw);
    position: relative;
}

.striped div .textTooltip {
    position: absolute;
    bottom: calc(77% + 1.5vw);
    min-width: calc(124px + 6vw);
    max-height: calc(124px + 6vw);
    align-self: center;
    background: white;
    color: black;
    padding: 10px;
    font-size: 16px;
    text-align: start;
    border: 1px solid rgba(80, 255, 50, .5);
    overflow-y: auto;
    border-radius: 6px;
    box-shadow: 0 10px 10px rgba(80, 255, 50, .09);
    display: none;
    right: 0px;
    left: calc(-17px + -.8vw);
    margin-right: auto;
}

.striped div .icon {
    font-size: 100px;
    transition: .3s;
}


.striped div h4 {
    font-size: calc(20px);
    margin-top: 15px;
}

.striped div img {
    max-height: 100px;
    max-width: 100px;
    transition: .4s;
}


.striped div:hover .icon {
    color: red;
    transform: scale(1.1);
}

.striped div:hover .textTooltip {
    display: block;
    z-index: 9;
}

.hover {
    transition: .5s;
}

.hover:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}

.center {
    display: flex;
    place-items: center;
}



/* ========================================================= */
/* Carousel  Carousel   Carousel  Carousel  Carousel  Carousel*/
/* ========================================================= */

.carousel {
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: row;
    place-items: center;
    justify-content: center;
    z-index: 999;
    padding: 10px;
    color: white;
}




.carousel .leftArrow,
.carousel .rightArrow {
    display: grid;
    place-items: center;
    width: calc(30px + 2vw);
    height: calc(30px + 2vw);
    font-size: calc(20px + 1vw);
    background: var(--golden);
    color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: .4s;
}

.carousel .rightArrow {
    position: absolute;
    right: 10px;
}

.carousel .leftArrow {
    position: absolute;
    left: 10px;
}

.carousel img {
    max-width: 80vw;
    max-height: 80vh;
}

.carousel .cancelBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999;
    background: var(--golden);
    width: calc(18px + 1.2vw);
    height: calc(18px + 1.2vw);
    display: block;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    font-size: calc(9px + .5vw);
    padding: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    transition: .5s;
    cursor: pointer;
}

.carousel .cancelBtn:hover,
.carousel .leftArrow:hover,
.carousel .rightArrow:hover {
    background-color: white;
    color: var(--golden);
}

/* ========================================================= */
/* Carousel  Carousel   Carousel  Carousel  Carousel  Carousel*/
/* ========================================================= */

/* ========================================================= */
/* gallery  gallery   gallery  gallery  gallery  gallery*/
/* ========================================================= */

.height600 {
    max-height: 600px;
    overflow-y: auto;
}

/* ========================================================= */
/* gallery  gallery   gallery  gallery  gallery  gallery*/
/* ========================================================= */

/* ========================================================= */
/* courses dropdown courses dropdown courses dropdown courses */
/* ========================================================= */

.dropdownTab {
    position: relative;
}

.dropdownTab .dropdownMenu {
    position: absolute;
    background: white;
    box-shadow: 0 20px 15px -5px rgba(0, 0, 0, .2);
    z-index: 99;
    padding: 10px 0px;
    border-radius: 5px;
    width: 150px;
    display: flex;
    flex-direction: column;
    display: none;
    transition: .5s;
}

.dropdownTab .dropdownMenu a {
    color: black !important;
    text-align: left;
    margin-top: 3px;
    margin-left: 15px !important;
    margin-bottom: 3px;
    transition: .5s;
}

.dropdownTab .dropdownMenu a:hover {
    color: var(--golden) !important;
}

.dropdownTab:hover .dropdownMenu {
    display: flex;
}

/* ========================================================= */
/* courses dropdown courses dropdown courses dropdown courses */
/* ========================================================= */



/* Sliding Menu */
/* Sliding Menu */
.SlidingMenu {
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    place-items: center;
    z-index: 9999;
    width: 100vw;
    min-height: 100%;
    background: linear-gradient(120deg, var(--golden), white);
    box-sizing: border-box;
}

.SlidingMenu .cancelBtn {
    position: absolute;
    top: 10px;
    right: 30px;
    cursor: pointer;
    transition: .4s;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: white;
    display: block;
    text-align: center;
    align-items: center;
}

.SlidingMenu .cancelBtn:hover {
    transform: scale(1.1);
    transform: rotate(3600deg);
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
}

.SlidingMenu img {
    max-height: calc(100px + 2vw);
}

.SlidingMenu a,
.SlidingMenu span {
    text-decoration: none;
    color: black;
    cursor: pointer;
}


/* Sliding Menu */
/* Sliding Menu */

/* sitemap */
.siteMapHoverLink {
    color: gray;
    transition: .3s;
    text-decoration: none;
    font-weight: 100;
}

.siteMapHoverLink:hover {
    padding-left: 10px;
    color: rgba(5, 5, 80, 0.789);
}

/* sitemap */

/* single post */
.max_post_img_height {
    max-height: 400px;
    width: auto;
    align-self: center;
}

.MultiplePostCardImageContainer {
    height: 250px;
    margin: auto;
}


.MultiplePostCardImageContainer .multiplePostCard {
    height: 250px !important;
}

.textCardBody {
    height: 230px;
}

.tag {
    background: var(--golden);
    padding: 3px 10px;
    border-radius: 3px;
    color: white;
    font-size: calc(15px + .2vw);
    text-transform: capitalize;
}

/* single post */


/* Cursor */
.pointer {
    cursor: pointer !important;
}

/* Cursor */


/* Slider  */
.Custom_Slider {
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    overflow-x: auto;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
}

.Custom_Slider ul {
    cursor: not-allowed;
}


.Custom_Slider::-webkit-scrollbar {
    height: 2px !important;
}

/* Track */
.Custom_Slider::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
}

/* Handle */
.Custom_Slider::-webkit-scrollbar-thumb {
    background: #888 !important;
}

/* Handle on hover */
.Custom_Slider::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

.Custom_Slider li {
    list-style: none;
    margin: 0px 20px;
    cursor: pointer;
}



/* Slider  */


@media screen and (max-width: 1000px) {
    .Custom_Slider {
        justify-content: flex-start;
    }

}


/* slider button */
.cusButton {
    background: var(--golden);
    padding: 5px 15px;
    font-size: calc(19px + .6vw);
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(40, 40, 40, .5);
    color: white;
}

/* slider button */


.floatingButton {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.floatingButton .circle {
    display: flex;
    justify-content: center;
    width: calc(60px + 3vw);
    height: calc(60px + 3vw);
    border-radius: 100%;
    background: var(--golden);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    color: white;
    font-weight: 600;
    font-size: calc(14px + .3vw);
    box-shadow: 0 0 20px -8px rgba(0, 0, 0, .5);
    border: 2px solid white;
    cursor: pointer;
    text-decoration: none;
    transition: .5s;
}

.floatingButton .circle span {
    line-height: 17px;
}

.floatingButton .circle:nth-child(3) {
    z-index: 1000;
}

.floatingButton .circle:nth-child(2) {
    z-index: 999;
    margin-bottom: calc(1px - (76px + 3vw));
}

.floatingButton .circle:nth-child(1) {
    z-index: 998;
    margin-bottom: calc(1px - (73px + 3vw));
}

.floatingButton .circle:hover {
    transform: scale(1.1);
}

.textImageWrapper {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: calc(100px + 1vw);
}

.textImageWrapper div {
    flex: 50% auto auto;
    width: 400px;
    min-width: 400px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textImageWrapper div:nth-child(1) {
    background: var(--golden);
    /* padding: 10px; */
}