@import url("./root.css");

body {
    background: rgba(250, 250, 250);
}

.uploadCard {
    max-width: 500px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

}

.registrationCard {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    height: auto;
    margin-top: 80px;
    position: relative;
    min-height: 400px;
}

.registrationCard .logoWrapper,
.registrationCard .formWrapper {
    flex: 300px 1 1;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px calc(10px + 2vw);
}

/* 
.registrationCard .logoWrapper {
    box-shadow: 10px 0 10px rgba(100, 100, 100);
    z-index: 2;
} */

.registrationCard .formWrapper {
    background: var(--golden);
}

.registrationCard .logoWrapper img {
    max-width: 300px;
}

.cusButton {
    padding: 6px 15px 4px 15px;
    width: auto;
    background: white;
    box-shadow: 0 10px 10px -5px rgba(100, 100, 100);
    transition: .5s;
}

.cusButton:hover {
    box-shadow: 0 0 0;
}

.pointer {
    cursor: pointer;
}

/* Blogs */

.uploadFormWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 0 4px -2px rgba(100, 100, 100);
    padding: 10px 10px 2px 10px;
    border-radius: 4px;
    max-width: 500px;
    align-self: center;
}

.uploadFormWrapper .userImage {
    display: flex;
}

.uploadFormWrapper .userImage {
    display: flex;
}

.uploadFormWrapper .userImage img {
    width: calc(45px + .5vw);
    height: calc(45px + .5vw);
    box-shadow: 0 0 4px -2px rgba(100, 100, 100);
    margin-right: 10px;
}

.blogsSort {
    display: flex;
    flex-direction: column;
}

/* Blogs */

/* Profile card */
.profileBanner {
    width: 100%;
    max-height: 250px;
    margin-top: calc(95px + 1vw);
}

.profileBanner img {
    width: 100%;
    max-height: 250px;
}

.userProileCard {
    max-width: 350px;
    min-height: 160px;
    height: auto;
    width: calc(100% - 110px);
    background: white;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 10px 10px 20px -10px rgba(100, 100, 100);
    margin-top: -80px;
}



.userProileCard .profileCardWrapper {
    width: 100px;
    height: 100px;
    margin-left: -50px;
    box-shadow: 0 0 10px rgba(100, 100, 100, .5);
}

.userProileCard .profileCardWrapper img {
    max-width: 100px;
    max-height: 100px;
}

.userProileCard .userDetailsWrapper {
    display: flex;
    flex-direction: column;
    font-size: calc(14px + .3vw);
    padding-left: 20px;
    font-weight: 500;
}


.userProileCard .userDetailsWrapper span {
    text-transform: capitalize;
    line-height: 22px;
}

.userProileCard .userDetailsWrapper span:nth-child(3) {
    text-transform: lowercase;
}

.viewProfileCard {
    position: absolute;
    right: 5px;
    bottom: 0px;
    font-weight: 600;
    cursor: pointer;
    font-size: 15px;
}

/* Profile card */